import React, { useState } from 'react';
import './landing.scss';
import '../../global/global.scss';
import github from '../../assets/images/github.png';
import logo from '../../assets/images/logo.png';
import ParticleScene from '../../ParticleScene';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import Confetti from 'react-confetti';

import jsIcon from '../../assets/images/js-icon.webp';
import dingusImage from '../../assets/images/dingus.jpeg';

const Landing = () => {
    const [showImage, setShowImage] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);

    const handleClick = () => {
        setShowImage(true);
        setShowConfetti(true);
    };

    return (
        <>
            <header>
            </header>
            <main>
                <div className={'card-wrapper'}>
                    <Card className={'card'} style={{position: 'relative', top: '10rem'}}>
                        <h1 id="greeting">
                            Hey, <span className="highlight">Ізабель.</span>
                        </h1>
                        <p className="intro">
                            Ich hoffe, dass dein <span className="highlight">Kissen</span> <br />heute Nacht <span className="highlight">auf beiden Seiten</span> kalt ist.
                        </p>
                        <div>
                            <Button className={'view-btn'} onClick={handleClick}>
                                <svg width="100%" height="100%" viewBox="0 0 180 60" preserveAspectRatio="none" className="border">
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" className="bg-line" />
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" className="hl-line" />
                                </svg>
                                {'KLICKE HIER FÜR EINE ÜBERRASCHUNG'}
                            </Button>
                        </div>
                        {showImage && (
                            <div className="image-container">
                                <img src={dingusImage} alt="Dingus" className="dingus-image" />
                            </div>
                        )}
                    </Card>
                </div>
               
                <ParticleScene />
            </main>
            <footer>
                <div className={'icon-container'}>
                    <div className={'icon-container'}>
                        <img src={github} alt="github" className={'github-icon'} />
                        <a href="https://github.com/n4mborgh1n1" className={'github'}>
                            GitHub
                        </a>
                    </div>
                </div>
            </footer>

            <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.2/gsap.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.2/ScrollTrigger.min.js"></script>
            <script src="https://unpkg.com/split-type"></script>

            {showConfetti && <Confetti />}
        </>
    );
};

export default Landing;

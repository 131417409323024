import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const ParticleScene = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000);
    mountRef.current.appendChild(renderer.domElement);

    const particleCount = 15000;
    const particles = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const velocities = new Float32Array(particleCount * 3);
    const sizes = new Float32Array(particleCount);

    const galaxyRadius = 100;
    const galaxyArmCount = 5;
    const galaxyArmOffset = Math.PI * 2 / galaxyArmCount;
    
    for (let i = 0; i < particleCount; i++) {
      const i3 = i * 3;
      const radius = Math.random() * galaxyRadius;
      const armAngle = (i % galaxyArmCount) * galaxyArmOffset;
      const spinAngle = radius * 0.3;
      const randomDisplacement = (Math.random() - 0.5) * 5;
      
      positions[i3] = Math.cos(armAngle + spinAngle) * radius + randomDisplacement;
      positions[i3 + 1] = (Math.random() - 0.5) * 5;
      positions[i3 + 2] = Math.sin(armAngle + spinAngle) * radius + randomDisplacement;
      
      velocities[i3] = (Math.random() - 0.5) * 0.1;
      velocities[i3 + 1] = (Math.random() - 0.5) * 0.1;
      velocities[i3 + 2] = (Math.random() - 0.5) * 0.1;

      sizes[i] = Math.random() * 1.5 + 0.5;
    }

    particles.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    particles.setAttribute('velocity', new THREE.BufferAttribute(velocities, 3));
    particles.setAttribute('size', new THREE.BufferAttribute(sizes, 1));

    const particleMaterial = new THREE.PointsMaterial({
      color: 0x00FFDD,
      size: .1,
      sizeAttenuation: true,
      vertexColors: false,
      transparent: true,
      opacity: 0.8,
      blending: THREE.AdditiveBlending
    });

    const particleSystem = new THREE.Points(particles, particleMaterial);
    scene.add(particleSystem);

    camera.position.z = 120;

    const animate = () => {
      requestAnimationFrame(animate);

      const positionsArray = particleSystem.geometry.attributes.position.array;
      const velocitiesArray = particleSystem.geometry.attributes.velocity.array;

      for (let i = 0; i < particleCount * 3; i += 3) {
        positionsArray[i] += velocitiesArray[i];
        positionsArray[i + 1] += velocitiesArray[i + 1];
        positionsArray[i + 2] += velocitiesArray[i + 2];

        const dx = positionsArray[i];
        const dy = positionsArray[i + 1];
        const dz = positionsArray[i + 2];
        const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);

        // Reduce velocity based on distance and minimum speed
        const pullStrength = 0.0001;
        velocitiesArray[i] -= (dx / distance) * pullStrength;
        velocitiesArray[i + 1] -= (dy / distance) * pullStrength;
        velocitiesArray[i + 2] -= (dz / distance) * pullStrength;
      }

      particleSystem.geometry.attributes.position.needsUpdate = true;

      particleSystem.rotation.y += 0.001;
      renderer.render(scene, camera);
    };

    animate();

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      mountRef.current.removeChild(renderer.domElement);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <div ref={mountRef} className={'particle-background'}></div>;
};

export default ParticleScene;
